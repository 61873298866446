import React from "react";
import IndexPage from "./index"

const IndexPageCZ = () => (
  <IndexPage i18n="cz"/>
);

export default IndexPageCZ;


